import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Checkbox } from "@remo-co/ui-core/src/components/Checkbox";
import { Container } from "@remo-co/ui-core/src/components/Container";
import { FormControlLabel } from "@remo-co/ui-core/src/components/FormControlLabel";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { FormEvent, useContext, useState } from "react";
import { Close } from "@remo-co/ui-core/src/icons/Close";
import EmailAlert from "modules/eventMemberList/components/EmailAlert";
import { Errors, trackError } from "modules/monitoring";
import { MANAGE_EVENT_CONTEXT } from "modules/manageEvent";
import { SERVER_EMAIL_LIMIT_EXCEEDED } from "modules/eventInvite/hooks/useEventInviteActions";
import { useI18n } from "i18n";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { useAttendeeInvite } from "modules/manageEvent/hooks/useAttendeeInvite";
import { ValidatedChipInput } from "@remo-co/ui-core/src/components/ValidatedChipInput";
import { isValidEmailAddress } from "@remo-co/email";
import useStyles from "./styles";

const EMAILS_ADD_LIMIT = 200;

interface Props {
  eventId: string;
  onClose: () => void;
}

const CLOSE_BUTTON_COLOR = "black";

const InviteGuests = ({ eventId, onClose }: Props): JSX.Element => {
  const styles = useStyles();
  const { t } = useI18n(["common", "manageEvent"]);
  const { addErrorNotification, addSuccessNotification } =
    useNotificationActions();
  const { state } = useContext(MANAGE_EVENT_CONTEXT);
  const [emailsInvalid, setEmailsInvalid] = useState(false);

  const { pendingAttendeeInvites = [], sendAttendeeInvite = true } =
    state ?? {};

  const [emails, setEmails] = useState<string[]>(
    Array.from(pendingAttendeeInvites),
  );
  const [isEmailInvite, setIsEmailInvite] = useState(sendAttendeeInvite);

  const {
    sendInviteAsync,
    isPending,
    error: inviteError,
  } = useAttendeeInvite();

  const handleAddToGuestList = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await sendInviteAsync({
        eventId,
        emails,
        force: false,
        isEmailInvitation: isEmailInvite,
      });
      addSuccessNotification({
        message: t("event:email.add.list"),
        position: "tc",
      });
      onClose();
    } catch (error) {
      const errorMessage = (error as Error).message;
      addErrorNotification({
        message: t(errorMessage),
        position: "tc",
      });
      trackError(errorMessage, {
        label: Errors.SEND_ATTENDEE_INVITE,
        eventId,
      });
    }
  };
  const handleEmailInvite = () => {
    setIsEmailInvite((prevValue) => !prevValue);
  };

  const showEmailLimitAlert =
    inviteError?.message === SERVER_EMAIL_LIMIT_EXCEEDED;
  const disableInviteButton =
    emails.length === 0 || emails.length > EMAILS_ADD_LIMIT || emailsInvalid;

  return (
    <Box className={styles.contentsContainer} padding={2}>
      <form onSubmit={handleAddToGuestList} data-testid="invite-guests">
        <Container
          flex
          justifyContent="space-between"
          fullWidth
          alignItems="center"
        >
          <Typography variant="h5" className={styles.defaultCursor}>
            {t("manageEvent:quick.add.guest")}
          </Typography>
          <Button
            variant="primary"
            color="gray"
            size="sm"
            onClick={onClose}
            isIconButton
            data-testid="close-button"
          >
            <Close htmlColor={CLOSE_BUTTON_COLOR} />
          </Button>
        </Container>
        <Container fullWidth top={10}>
          <ValidatedChipInput
            value={emails}
            onChange={setEmails}
            validate={(someInvalid) => setEmailsInvalid(someInvalid)}
            limit={EMAILS_ADD_LIMIT}
            placeholder={t("manageEvent:emails.separate")}
            showLimit={false}
            invalidInputsMessage={t("email.invalid")}
            getRemainingMessage={(key) => t("email.remaining", { key })}
            getExceededMessage={(key) => t("email.exceeded", { key })}
            customValidator={isValidEmailAddress}
          />
        </Container>
        <Box className={styles.defaultCursor} marginX={0} marginY={0.5}>
          <Typography variant="caption" color="textSecondary">
            {t("manageEvent:add.emails", { key: EMAILS_ADD_LIMIT })}
          </Typography>
        </Box>
        {showEmailLimitAlert && <EmailAlert />}
        <Box marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={handleEmailInvite}
                checked={isEmailInvite}
              />
            }
            label={t("manageEvent:email.invitations")}
          />
        </Box>
        <Container flex justifyContent="flex-end">
          <Button
            variant="primary"
            loading={isPending}
            color="blue"
            data-testid="add-to-guest-button"
            type="submit"
            disabled={disableInviteButton}
          >
            {t("manageEvent:add.guests")}
          </Button>
        </Container>
      </form>
    </Box>
  );
};

export default InviteGuests;
