import { useContext, useState } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { Button } from "@remo-co/ui-core/src/components/Button";
import { Link as UILink } from "@remo-co/ui-core/src/components/Link";
import { useI18n } from "i18n";
import { IEvent } from "modules/event/types";
import { Link } from "react-router-dom";
import {
  useCustomQuestionOperations,
  useCustomRegistrationQuestions,
} from "modules/registration";
import { ICustomRegistrationQuestion } from "modules/registration/types";
import useNotificationActions from "modules/notification/hooks/useNotificationActions";
import { Events, TRACKING_CONTEXT } from "modules/tracking";
import { QuestionType } from "modules/registration/constants";
import { useStyles } from "./styles";
import { EmptyState } from "../EmptyState";
import { QuestionsList } from "../QuestionsList";
import { QuestionFormDialog } from "../QuestionFormDialog";

interface Props {
  eventData: IEvent;
}

export const RegistrationTab = ({ eventData }: Props) => {
  const { track } = useContext(TRACKING_CONTEXT);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [preloadedQuestion, setPreloadedQuestion] =
    useState<ICustomRegistrationQuestion | null>(null);
  const { addSuccessNotification } = useNotificationActions();
  const { t } = useI18n(["common", "eventForm", "url"]);
  const { questions, status: fetchStatus } = useCustomRegistrationQuestions(
    eventData?.id,
  );

  const handleFormClose = () => {
    setIsFormVisible(false);
    setPreloadedQuestion(null);
  };

  const {
    reorderQuestions,
    deleteQuestion,
    createQuestion,
    updateQuestion,
    isLoading,
    status,
  } = useCustomQuestionOperations(eventData?.id, {
    onCreateSuccess: (type: QuestionType) => {
      track(Events.CUSTOM_QUESTION_ADDED, {
        eventId: eventData._id,
        eventType: eventData.eventType,
        eventOutcome: eventData.eventOutcome,
        type,
      });
      addSuccessNotification({
        message: t("eventForm:question.successfully.created"),
      });
      handleFormClose();
    },
    onUpdateSuccess: () => {
      addSuccessNotification({
        message: t("eventForm:question.successfully.updated"),
      });
      handleFormClose();
    },
    onDeleteSuccess: () => {
      addSuccessNotification({
        message: t("eventForm:question.successfully.deleted"),
      });
    },
  });
  const styles = useStyles();

  const handleCreateQuestionClick = () => {
    track(Events.CUSTOM_QUESTION_STARTED, {
      eventId: eventData._id,
      eventType: eventData.eventType,
      eventOutcome: eventData.eventOutcome,
    });
    setIsFormVisible(true);
  };

  const handleUpdateQuestionClick = (question: ICustomRegistrationQuestion) => {
    setIsFormVisible(true);
    setPreloadedQuestion(question);
  };

  const showEmptyState =
    (!eventData?.id || !questions.length) && fetchStatus !== "pending";

  return (
    <>
      <Box>
        <div>
          <Typography variant="h3">
            {t("eventForm:registration.form")}
          </Typography>
          <div>
            <Typography variant="body1" className={styles.description}>
              {t("eventForm:registration.form.description.1")}{" "}
              <Link
                to={`/event/invitations/${eventData._id ?? ""}`}
                className={styles.link}
              >
                {t("eventForm:guest.list.link")}
              </Link>
            </Typography>
          </div>
          <Typography variant="body1" className={styles.note}>
            {t("eventForm:registration.form.description.2")}
          </Typography>
          <Button
            variant="text"
            color="dark"
            size="sm"
            className={styles.helpLinkButton}
            href={t("url:custom.questions.help.link")}
            component={UILink}
            openInNewTab
          >
            {t("eventForm:custom.questions.learn.more")}
          </Button>
        </div>
        {showEmptyState && (
          <EmptyState
            eventId={eventData?.id}
            onCreateClick={handleCreateQuestionClick}
          />
        )}
        {Boolean(questions.length) && (
          <QuestionsList
            questions={questions}
            isLoading={isLoading}
            status={status}
            reorderQuestions={reorderQuestions}
            deleteQuestion={deleteQuestion}
            onCreateClick={handleCreateQuestionClick}
            onUpdateClick={handleUpdateQuestionClick}
          />
        )}
      </Box>
      {isFormVisible && eventData?.id && (
        <QuestionFormDialog
          eventId={eventData?.id}
          isLoading={isLoading}
          preloadedQuestion={preloadedQuestion}
          order={questions.length + 1}
          createQuestion={createQuestion}
          updateQuestion={updateQuestion}
          onClose={handleFormClose}
        />
      )}
    </>
  );
};
